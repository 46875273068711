.icons {
    color: var(--color-roxo);
    font-size: 1.5rem !important;
}

.icons-submenu {
    color: var(--color-roxo);
    font-size: 1.3rem !important
}

.sidebar-open {
    animation: opensidebar 0.5s;
    display: block;
    left: 0;
}

@keyframes opensidebar {
    from {
        left: -314px
    }
    to {
        left: 0
    }
}

.sidebar-close {
    animation: closesidebar 0.5s;
    display: none;
    left: -314px;
}

@keyframes closesidebar {
    from {
        left: 0
    }
    to {
        left: -314px
    }
}