.password-criteria {
    list-style-type: none;
    padding-left: 0;
}

.password-criteria li {
    color: red;
}

.password-criteria li.valid {
    color: green;
}

.input-group-text {
    cursor: pointer;
}
.login-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    width: 100%;

}