.fieldset-socio {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0 10px 0 10px;
  overflow-y: auto;
  height: 400px;
  margin-top: 10px;
}

.fieldset-socio-no-results {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0 10px 0 10px;
  overflow-y: auto;
  height: 100px;
  margin-top: 10px;
}

.fieldset-socio-legend {
  font-size: 16px;
  width: auto;
  margin: 0 30px 0 30px;
  padding: 0 15px 0 15px;
  text-align: left;
}