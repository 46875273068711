.colunaCampanha {
  height: 40px;
  color: white;
  background: linear-gradient(360deg, #2d92f7 0%, #4cb4ff 100%);
  text-align: center;
  min-width: 150px;
}

.linhaGreen {
  height: 40px;
  background: #f0fff0;
  border-right: 3px solid white;
  border-bottom: 3px solid white;
}

.linhaRed {
  height: 40px;
  background: #ffe4e1;
  border-right: 3px solid white;
  border-bottom: 3px solid white;
  text-align: center;
}

.linhaGreen:not(:first-child) {
  text-align: center;
}

.button {
  background: #8cbdb3;
  width: 16px;
  height: 10px;
  border-radius: 3px;
  text-align: center;
  color: white;
  margin-right: 10px;
  padding-bottom: 18px;
  cursor: pointer;
}

.tfoot-checkbox {
  text-align: center;
  width: 50px;
  font-weight: bold;
  font-size: 16px;
}

.tfoot-cell {
  padding: 10px;
  min-width: 200px;
  font-weight: bold;
  text-align: left;
}

.tfoot-cell.align-right {
  text-align: right;
}

.tfoot-cell.align-left {
  text-align: left;
}
