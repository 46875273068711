.colunaEsteira{
    height: 40px; 
    color: white;
    background:  linear-gradient(360deg, #2D92F7 0%, #4CB4FF 100%);
    text-align: center
}
 
.linhaGreen{
    height: 40px;
    background: #F0FFF0;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
}

.linhaRed{
    height: 40px;
    background: #FFE4E1 ;
    border-right: 3px solid white;
    border-bottom: 3px solid white;
    text-align: center;
}
 
 .linhaGreen:not(:first-child){
     text-align: center;
 }
 
 .button{
     background: #8CBDB3;
     width: 16px;
     height: 10px;
     border-radius: 3px;
     text-align: center;
     color: white;
     margin-right: 10px;
     padding-bottom: 18px;
     cursor: pointer;
 }