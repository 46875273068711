.icons-breadcrumb :first-child {
  color: white;
  font-size: 14px;
  margin-bottom: 15px;
}
.icons-breadcrumb {
  margin-left: 1%;
  margin-top: 18px;
}
.text-breadcrumb {
  font-size: 15px;
  color: white;
  margin-left: 0.5%;
  margin-top: 20px;
}
