.container-stepbar{
    display: flex;
    width: 100%;
    justify-content: center;
}

.linha{
    margin-top: 15px;
    width: 43%;
}

.linha:not(.noLine){
    border-top: 1px solid #73D13D; 
}

.linha-cinza{
    margin-top: 15px;
    width: 43%;
    border-top: 1px solid #838383; 
}

.numero-step{
    width: 32px; 
    height: 32px; 
    min-width: 32px!important;
    background: var(--color-verde-claro);
    border-radius: 32px;
    padding:7px 2px 0 0; 
    margin:0 8px 0 8px; 
    color: white;
}

.descricao-step{
   font-size: 12px; 
   margin-bottom: 4px;
   min-width: 150px;
   max-width: 250px;
}

.data-hora{
   font-size: 10px; 
   color: var(--color-cinza-medio);
}