.card-paramentros {
    background: var(--color-quase-branco);
    min-height: 80vh;
    width: 100%;
    border: 1px solid #CCCFDE;
    border-radius: 5px;
    padding: 15px 20px;
    color: var(--color-preto);
    overflow-x: auto;
}
