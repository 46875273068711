.password-criteria {
    list-style: none;
    padding: 20px;
  }
  
  .password-criteria li {
    color: red;
  }
  
  .password-criteria li.valid {
    color: green;
  }
  