.col-custom {
    padding:0 5px !important;
}

.titulo {
    font-weight: 700;
}

label {
    white-space: nowrap;
}

.trash {
    margin-top: 10px;
    text-align: right;
}